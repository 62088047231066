.blog {
    .hero-section {
        display: none;
        width: 100% !important;
    }

    .main-container {
        width: 100% !important;
    }

    .page-title {
        color: $color__lochmara;
        font-size: 35px;
        font-weight: 700;
        line-height: 46px;
        margin: 0 0 32px;
    }

    .entries {
        .entry-card {
            @include flex__direction(row-reverse);
            column-gap: 35px;

            .ct-image-container {
                margin: 0 !important;
                max-width: 260px;
                min-width: 260px;
            }

            .card-content {
                .entry-meta {
                    margin: 0 0 5px !important;
                }

                .entry-title {
                    margin: 0 0 10px;
                }

                .entry-excerpt {
                    margin: 0 0 30px;
                }

                .entry-button {
                    line-height: 1;
                    padding: 5px 22px;
                }
            }
        }
    }

    .ct-pagination {
        @include flex__container();
        @include flex__justify-content(flex-start);
        column-gap: 10px;
        font-size: 16px;

        > div {
            @include flex__container();
            column-gap: 10px;
            margin: 0;

            .page-numbers {
                flex: 0 0 32px;
                height: 32px;
                margin: 0;
                width: 32px;
            }

            a {
                background: $color__safety-orange;
                color: $color__white;

                &:hover {
                    background: $color__white-smoke;
                    color: $color__safety-orange;
                }
            }
        }

        .prev,
        .next {
            background: none;
            border: 0;
            color: $color__safety-orange;
            display: block;
            font-size: 0;
            height: 32px;
            padding: 0;
            position: relative;
            width: 10px;

            svg {
                display: none;
            }

            &::before {
                content: $icon__angle-right;
                font-family: $font-family__icomoon;
                font-size: 13px;
                left: 50%;
                line-height: 1;
                position: absolute;
                top: 50%;
            }
        }

        .prev {
            &::before {
                @include transform__translateXY_rotate(-50%, -50%, 180deg);
            }
        }

        .next {
            &::before {
                @include transform__translateXY_rotate(-50%, -50%, 0deg);
            }
        }
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .blog {
        .hero-section {
            margin-bottom: 40px !important;
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .blog {
        .hero-section {
            margin-bottom: 30px !important;
        }

        .page-title {
            font-size: 32px;
            line-height: 35px;
            margin: 0 0 20px;
        }

        .entries {
            .entry-card {
                column-gap: 25px;
    
                .ct-image-container {
                    max-width: 220px;
                    min-width: 220px;
                }
    
                .card-content {
                    .entry-meta {
                        font-size: 16px;
                    }
    
                    .entry-title {
                        font-size: 21px;
                        line-height: 1.3;
                    }
    
                    .entry-excerpt {
                        font-size: 15px;
                        margin: 0 0 20px;
                    }
                }
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .blog {
        .hero-section {
            margin-bottom: 20px !important;
        }

        .page-title {
            font-size: 30px;
            line-height: 34px;
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .blog {
        .page-title {
            font-size: 27px;
            line-height: 30px;
            margin: 0 0 15px;
        }

        .entries {
            .entry-card {
                column-gap: 20px;
    
                .ct-image-container {
                    max-width: 180px;
                    min-width: 180px;
                }
    
                .card-content {
                    .entry-meta {
                        font-size: 14px;
                    }
    
                    .entry-title {
                        font-size: 18px;
                    }
    
                    .entry-excerpt {
                        margin: 0 0 15px;
                    }
                }
            }
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .blog {
        .hero-section {
            margin-bottom: 15px !important;
        }

        .page-title {
            font-size: 25px;
            line-height: 28px;
        }

        .entries {
            .entry-card {
                @include flex__direction(column);
                row-gap: 15px;

                .ct-image-container {
                    max-width: 100%;
                    min-width: 100%;
                }
            }
        }

        .ct-pagination {
            @include flex__justify-content(space-between);

            > div {
                display: none;
            }

            .prev,
            .next {
                &::before {
                    font-size: 16px;
                }
            }
        }
    }
}
