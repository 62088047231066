.address-section {
    margin: 45px 0 0;

    h2 {
        color: $color__lochmara;
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        margin: 0 0 10px !important;
    }

    p {
        a {
            color: $color__black;
            text-decoration: none;

            &:hover {
                color: $color__safety-orange;
            }
        }
    }
}

.form-section {
    margin: 10px 0 0;
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .address-section {
        margin: 30px 0 0;
    
        h2 {
            font-size: 25px;
            line-height: 30px;
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .address-section {
        margin: 20px 0 0;

        h2 {
            font-size: 22px;
            line-height: 25px;
        }
    }

    .form-section {
        margin: 0;
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .address-section {
        h2 {
            font-size: 20px;
        }
    }
}
