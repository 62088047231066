@font-face {
    font-family: 'icomoon';
    src:  url('../../src/fonts/icomoon/icomoon.eot?jrc6a4');
    src:  url('../../src/fonts/icomoon/icomoon.eot?jrc6a4#iefix') format('embedded-opentype'),
      url('../../src/fonts/icomoon/icomoon.ttf?jrc6a4') format('truetype'),
      url('../../src/fonts/icomoon/icomoon.woff?jrc6a4') format('woff'),
      url('../../src/fonts/icomoon/icomoon.svg?jrc6a4#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
