.ons-team-content {
    h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 27px;
        margin: 0 0 15px;
    }
}

.team-members-block {
    margin: 0 0 50px;

    .team-members {
        @include flex__container();
        @include flex__wrap(wrap);
        column-gap: 36px;
        list-style: none;
        margin: 0;
        padding: 0;
        row-gap: 40px;

        .team-member {
            @include border-radius__each(20px, 20px, 0, 20px);
            @include box-shadow(0, 3px, 6px, 0, rgba(0, 0, 0, 0.16));
            @include flex__container();
            @include flex__direction(column);
            background: $color__white;
            margin: 0;
            width: calc((100% - 3 * 36px) / 4);

            .image-wrapper {
                display: block;
                padding-top: 68.408%;
                position: relative;
                width: 100%;

                .image-container {
                    @include flex__container();
                    @include flex__justify-content(center);
                    @include flex__align-items(center);
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;

                    img {
                        margin: 0;
                        max-height: 100%;
                        width: auto;
                    }
                }
            }

            .item-details {
                @include flex__container();
                @include flex__direction(column);
                @include flex__grow-shrink-basis(1, 1, auto);
                padding: 30px 9.2% 40px;

                .top {
                    @include flex__container();
                    @include flex__direction(column);
                    gap: 8px;
                    width: 100%;

                    .member-role {
                        color: $color__pelorous;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 21px;
                    }

                    .member-name {
                        color: $color__lochmara;
                        font-size: 22px;
                        font-weight: 500;
                        line-height: 29px;
                    }
                }

                .bottom {
                    @include flex__container();
                    @include flex__direction(column);
                    @include flex__grow-shrink-basis(1, 1, auto);
                    border-top: 1px solid $color__lochmara;
                    gap: 15px;
                    margin: 20px 0 0;
                    overflow: hidden;
                    padding: 20px 0 0;
                    width: 100%;

                    .label {
                        color: $color__lochmara;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 30px;
                        min-width: 80px;
                    }

                    .value {
                        color: $color__black;
                        font-size: 16px;
                        line-height: 30px;

                        a {
                            color: $color__safety-orange;

                            &:hover {
                                color: $color__black;
                            }
                        }
                    }

                    .member-working-time,
                    .member-email {
                        @include flex__container();
                        gap: 16px;
                    }

                    .member-working-time {
                        @include flex__grow-shrink-basis(1, 1, auto);
                    }

                    .member-email {
                        .value {
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop XL (max-width: 1670px)
//  _____________________________________________

@media only screen and (max-width: 1670px) {
    .team-members-block {
        margin: 0 0 40px;

        .team-members {
            column-gap: 25px;
            row-gap: 30px;

            .team-member {
                width: calc((100% - 3 * 25px) / 4);

                .item-details {
                    padding: 25px 7% 30px;

                    .top {
                        .member-role {
                            font-size: 15px;
                            line-height: 20px;
                        }

                        .member-name {
                            font-size: 19px;
                            line-height: 25px;
                        }
                    }

                    .bottom {
                        gap: 10px;

                        .label {
                            font-size: 14px;
                            line-height: 26px;
                            min-width: 70px;
                        }

                        .value {
                            font-size: 14px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .team-members-block {
        .team-members {
            column-gap: 15px;
            row-gap: 25px;

            .team-member {
                width: calc((100% - 3 * 15px) / 4);

                .item-details {
                    padding: 20px 5% 25px;

                    .top {
                        .member-role {
                            font-size: 14px;
                        }

                        .member-name {
                            font-size: 17px;
                        }
                    }

                    .bottom {
                        gap: 8px;

                        .value {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .team-members-block {
        .team-members {
            column-gap: 20px;
            row-gap: 25px;

            .team-member {
                width: calc((100% - 2 * 20px) / 3);

                .item-details {
                    padding: 25px 7% 30px;

                    .top {
                        .member-role {
                            font-size: 16px;
                            line-height: 21px;
                        }

                        .member-name {
                            font-size: 20px;
                            line-height: 28px;
                        }
                    }

                    .bottom {
                        .label {
                            font-size: 15px;
                            min-width: 75px;
                        }

                        .value {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .team-members-block {
        .team-members {
            column-gap: 10px;
            row-gap: 20px;

            .team-member {
                width: calc((100% - 2 * 10px) / 3);

                .item-details {
                    padding: 20px 7% 25px;

                    .top {
                        .member-role {
                            font-size: 15px;
                            line-height: 20px;
                        }

                        .member-name {
                            font-size: 18px;
                            line-height: 25px;
                        }
                    }

                    .bottom {
                        margin: 15px 0 0;
                        padding: 15px 0 0;

                        .label {
                            font-size: 15px;
                            min-width: 75px;
                        }

                        .value {
                            font-size: 14px;
                        }

                        .member-working-time,
                        .member-email {
                            @include flex__direction(column);
                            gap: 0;
                        }

                        .member-working-time {
                            @include flex__grow-shrink-basis(0, 1, auto);
                        }
                    }
                }
            }
        }
    }
}

//
//  Mobile L (max-width: 767px)
//  _____________________________________________

@media only screen and (max-width: 767px) {
    .team-members-block {
        .team-members {
            column-gap: 20px;
            row-gap: 30px;

            .team-member {
                width: calc((100% - 20px) / 2);

                .item-details {
                    padding: 25px 7% 30px;
                }
            }
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .team-members-block {
        .team-members {
            column-gap: 15px;
            row-gap: 25px;

            .team-member {
                width: calc((100% - 15px) / 2);

                .item-details {
                    padding: 20px 7% 25px;
                }
            }
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .team-members-block {
        .team-members {
            .team-member {
                margin: 0 auto;
                max-width: 383px;
                width: 100%;

                .item-details {
                    padding: 25px 7% 30px;
                }
            }
        }
    }
}
