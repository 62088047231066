.page {
    .ct-featured-image {
        padding-bottom: 70px;

        .ct-image-container {
            display: block;
        }
    }

    .page-title {
        color: $color__lochmara;
        font-size: 35px;
        font-weight: 700;
        line-height: 46px;
        margin: 0 0 32px;

        .onze-scholen & {
            margin: 0 0 50px;
            text-align: center;
        }

        .ons-team & {
            margin: 0 0 60px;
        }
    }
}

//
//  Desktop XL (max-width: 1670px)
//  _____________________________________________

@media only screen and (max-width: 1670px) {
    .page {
        .page-title {
            .ons-team & {
                margin: 0 0 40px;
            }
        }
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .page {
        .hero-section {
            margin: 0 auto 40px !important;
        }

        .ct-featured-image {
            padding-bottom: 50px;
        }

        .page-title {
            .onze-scholen & {
                margin: 0 0 35px;
            }

            .ons-team & {
                margin: 0 0 30px;
            }
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .page {
        .hero-section {
            margin: 0 auto 30px !important;
        }

        .ct-featured-image {
            padding-bottom: 30px;
        }

        .page-title {
            font-size: 32px;
            line-height: 35px;
            margin: 0 0 20px;
        }

        .entry-content {
            p {
                font-size: 15px;
                line-height: 25px;
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .page {
        .hero-section {
            margin: 0 auto 20px !important;
        }

        .page-title {
            font-size: 30px;
            line-height: 34px;
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .page {
        .page-title {
            font-size: 27px;
            line-height: 30px;
            margin: 0 0 15px;
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .page {
        .hero-section {
            margin: 0 auto 15px !important;
        }

        .ct-featured-image {
            padding-bottom: 20px;
        }

        .page-title {
            font-size: 25px;
            line-height: 28px;
            margin: 0 0 12px;
        }

        .entry-content {
            p {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}
