.ct-container,
#main-container {
    .site-main {
        overflow: hidden;

        > .ct-container {
            width: calc(100% - 30px);
        }
    }
}

.ct-container-narrow,
.ct-featured-image,
.hero-section,
.main-container {
    width: calc(100% - 30px) !important;
}

.main-container {
    > .gb-inside-container {
        @include flex__container();
        @include flex__justify-content(space-between);
        margin: 0 0 70px;
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .main-container {
        > .gb-inside-container {
            margin: 0 0 50px;
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .main-container {
        &.two-columns-left {
            > .gb-inside-container {
                @include flex__direction(column);
            }
        }
        
    }
}
