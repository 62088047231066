// A map of breakpoints.
$breakpoints: (
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px,
);

//
//  Colors
//  ---------------------------------------------

$color__apple-green: #e5efe5;
$color__black: #000000;
$color__danube: #6786bd;
$color__dark-magenta: #95077c;
$color__fire-engine-red: #d12420;
$color__forest-green: #21951d;
$color__gamboge: #dfad13;
$color__green: #006400;
$color__indigo: #5e0072;
$color__lochmara: #345da7;
$color__mandarian-orange: #9c1f2d;
$color__pelorous: #33a0c6;
$color__polo-blue: #809ac8;
$color__rust: #c02b0a;
$color__safety-orange: #fb6600;
$color__sunshade: #fc8c40;
$color__snow: #fff9f9;
$color__watercourse: #016548;
$color__white: #ffffff;
$color__white-smoke: #f2f2f2;

//
//  Icons Icomoon
//  ---------------------------------------------

$icon__angle-right: '\e902';
$icon__arrow-right: '\e900';
$icon__phone: '\e901';

//
//  Typography
//  ---------------------------------------------

$font-family__icomoon: 'icomoon';
