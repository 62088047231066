.gform_wrapper {
    &.gravity-theme {
        @include flex__container();
        @include flex__direction(column);
        max-width: 550px;

        .gform_heading {
            @include order(1);
            margin: 0 0 15px;
        }
    
        .gform_title {
            color: $color__lochmara;
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            margin: 0 0 15px;
        }
    
        .gform_required_legend {
            display: none;
        }

        .gform_validation_errors {
            @include order(2);
            margin-top: 0;
        }

        form {
            @include order(3);
        }

        .gform_body {
            .gfield_label {
                font-weight: 400;
                margin-bottom: 5px;
            }

            input[type=color],
            input[type=date],
            input[type=datetime-local],
            input[type=datetime],
            input[type=email],
            input[type=month],
            input[type=number],
            input[type=password],
            input[type=search],
            input[type=tel],
            input[type=text],
            input[type=time],
            input[type=url],
            input[type=week],
            select,
            textarea {
                @include border-radius(0);
                background: $color__white-smoke;
                border-color: $color__white-smoke;
                color: $color__black;
                font-size: 14px;
                line-height: 18px;

                &::-webkit-input-placeholder {
                    color: $color__black;
                    opacity: 1;
                }
        
                &::-moz-placeholder {
                    color: $color__black;
                    opacity: 1;
                }
        
                &:-ms-input-placeholder {
                    color: $color__black;
                    opacity: 1;
                }
        
                &:-moz-placeholder {
                    color: $color__black;
                    opacity: 1;
                }

                &[aria-invalid=true] {
                    background: $color__snow;
                    border-color: $color__rust;
                }
            }

            input[type=color],
            input[type=date],
            input[type=datetime-local],
            input[type=datetime],
            input[type=email],
            input[type=month],
            input[type=number],
            input[type=password],
            input[type=search],
            input[type=tel],
            input[type=text],
            input[type=time],
            input[type=url],
            input[type=week],
            select {
                height: 38px;
                padding: 5px 15px;
            }

            textarea {
                max-height: 120px;
                padding: 10px 15px;
                resize: none;
            }

            .validation_message {
                background: none;
                border: 0;
                color: $color__rust;
                font-size: 12px;
                line-height: 1;
                padding: 0;
            }
        }

        .gform_footer {
            margin: 35px 0 0 !important;
            padding: 0 !important;

            input[type=submit] {
                background: $color__white;
                border: 2px solid $color__safety-orange;
                color: $color__safety-orange;
                margin: 0;
                padding: 5px 25px;

                &:hover {
                    background: $color__safety-orange;
                    color: $color__white;
                }
            }
        }
    }
}

.gform_confirmation_wrapper {
    .gform_confirmation_message {
        background: $color__apple-green;
        border: 1px solid $color__green;
        color: $color__green;
        font-size: 14px;
        line-height: 1.2;
        padding: 10px 20px;
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .gform_wrapper {
        &.gravity-theme {
            .gform_heading {
                margin: 0 0 10px;
            }

            .gform_title {
                font-size: 25px;
                line-height: 30px;
                margin: 0 0 10px;
            }

            .gform_footer {
                margin: 25px 0 0 !important;
    
                input[type=submit] {
                    font-size: 17px;
                    line-height: 28px !important;
                }
            }
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .gform_wrapper {
        &.gravity-theme {
            max-width: 100%;

            .gform_heading {
                margin: 0 0 5px;
            }

            .gform_title {
                font-size: 22px;
                line-height: 25px;
                margin: 0 0 5px;
            }

            .gform_footer {
                margin: 15px 0 0 !important;
    
                input[type=submit] {
                    font-size: 15px;
                    line-height: 22px !important;
                }
            }
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .gform_wrapper {
        &.gravity-theme {
            .gform_title {
                font-size: 20px;
            }
        }
    }
}
