.agenda-items {
    @include flex__container();
    @include flex__direction(column);
    list-style: none;
    margin: 0;
    max-width: 990px;
    padding: 0;
    row-gap: 30px;

    .agenda-item {
        @include border-radius__each(0, 0, 0, 20px);
        @include box-shadow(0, 3px, 6px, 0, rgba(0, 0, 0, 0.16));
        @include flex__container();
        background: $color__white-smoke;
        column-gap: 25px;
        margin: 0;
        padding: 35px 30px;

        .event-date {
            .event-label {
                @include border-radius(15px);
                @include flex__container();
                @include flex__align-items(center);
                @include flex__direction(column);
                @include flex__justify-content(center);
                color: $color__white;
                height: 76px;
                width: 74px;

                .month {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1;
                    text-transform: uppercase;
                }

                .day {
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 1;
                }
            }
        }

        .event-details {
            .event-title {
                color: $color__lochmara;
                display: block;
                font-size: 25px;
                font-weight: 500;
                line-height: 33px;
                margin: 0 0 15px;

                a {
                    color: $color__lochmara;

                    &:hover {
                        color: $color__safety-orange;
                    }
                }
            }
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .agenda-items {
        .agenda-item {
            .event-details {
                .event-title {
                    font-size: 22px;
                    line-height: 26px;
                    margin: 0 0 10px;
                }
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .agenda-items {
        .agenda-item {
            padding: 30px 25px;
        }
    }
}

//
//  Mobile L (max-width: 767px)
//  _____________________________________________

@media only screen and (max-width: 767px) {
    .agenda-items {
        row-gap: 25px;

        .agenda-item {
            padding: 25px 20px;

            .event-date {
                .event-label {
                    height: 66px;
                    width: 64px;

                    .month {
                        font-size: 16px;
                    }

                    .day {
                        font-size: 32px;
                    }
                }
            }

            .event-details {
                .event-title {
                    font-size: 20px;
                }
            }
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .agenda-items {
        row-gap: 20px;

        .agenda-item {
            column-gap: 20px;

            .event-date {
                .event-label {
                    height: 60px;
                    width: 58px;

                    .month {
                        font-size: 14px;
                    }

                    .day {
                        font-size: 28px;
                    }
                }
            }

            .event-details {
                .event-excerpt {
                    font-size: 15px;
                    line-height: 1.7;
                }
            }
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .agenda-items {
        .agenda-item {
            @include flex__direction(column);
            row-gap: 20px;
            padding: 18px 16px;

            .event-date {
                @include flex__align-self(center);

                .event-label {
                    height: 54px;
                    width: 52px;

                    .month {
                        font-size: 13px;
                    }

                    .day {
                        font-size: 25px;
                    }
                }
            }
        }
    }
}
