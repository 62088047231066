.onze-scholen-items {
    width: 100% !important;

    > .gb-inside-container {
        @include flex__container();
        @include flex__wrap(wrap);
        column-gap: 16px;
        row-gap: 36px;
    }

    .item {
        @include border-radius__each(20px, 20px, 0, 20px);
        @include box-shadow(0, 3px, 6px, 0, rgba(0, 0, 0, 0.16));
        overflow: hidden;
        width: calc((100% - 3 * 16px) / 4);

        > .gb-inside-container {
            @include flex__container();
            @include flex__direction(column);
            height: 100%;
        }

        .wp-block-image {
            display: block;
            margin: 0;
            padding-top: 65.327%;
            position: relative;
            width: 100%;

            a {
                @include flex__container();
                @include flex__justify-content(center);
                @include flex__align-items(center);
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;

                img {
                    margin: 0;
                    max-height: 100%;
                    width: auto;
                }
            }
        }

        .item-details {
            height: 100%;

            > .gb-inside-container {
                @include flex__container();
                @include flex__direction(column);
                @include flex__justify-content(space-between);
                height: 100%;
                padding: 25px 8.794% 35px;
            }

            h2 {
                color: $color__lochmara;
                font-size: 25px;
                font-weight: 500;
                line-height: 28px;
                margin: 0 0 25px;

                a {
                    color: $color__lochmara;

                    &:hover {
                        color: $color__safety-orange;
                    }
                }
            }

            p {
                margin: 0 0 18px;

                a {
                    color: $color__black;
                    text-decoration: none;

                    &:hover {
                        color: $color__safety-orange;
                    }
                }

                &.btn {
                    margin: 0;

                    a {
                        color: $color__safety-orange;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 1.2;
                        padding-right: 28px;
                        position: relative;

                        &::after {
                            @include transform__translateY(-50%);
                            content: $icon__arrow-right;
                            font-family: $font-family__icomoon;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 1;
                            position: absolute;
                            right: 0;
                            top: 50%;
                        }

                        &:hover {
                            color: $color__lochmara;
                        }
                    }
                }
            }
        }

        &.main-item {
            .item-details {
                > .gb-inside-container {
                    background: $color__lochmara;

                    h2 {
                        color: $color__white;

                        a {
                            color: $color__white;

                            &:hover {
                                color: $color__safety-orange;
                            }
                        }
                    }

                    p {
                        color: $color__white;

                        a {
                            color: $color__white;

                            &:hover {
                                color: $color__safety-orange;
                            }
                        }

                        &.btn {
                            a {
                                color: $color__white;

                                &::after {
                                    color: $color__safety-orange;
                                }

                                &:hover {
                                    color: $color__safety-orange;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .onze-scholen-items {
        .item {
            .item-details {
                > .gb-inside-container {
                    padding: 20px 8% 25px;
                }

                h2 {
                    font-size: 20px;
                    line-height: 25px;
                    margin: 0 0 15px;
                }

                p {
                    font-size: 15px;
                    line-height: 1.8;
                    margin: 0 0 15px;

                    &.btn {
                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .onze-scholen-items {
        .item {
            .item-details {
                h2 {
                    font-size: 18px;
                }

                p {
                    font-size: 14px;

                    &.btn {
                        a {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .onze-scholen-items {
        .item {
            width: calc((100% - 2 * 16px) / 3);
        }
    }
}

//
//  Mobile L (max-width: 767px)
//  _____________________________________________

@media only screen and (max-width: 767px) {
    .onze-scholen-items {
        .item {
            width: calc((100% - 16px) / 2);
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .onze-scholen-items {
        .item {
            width: 100%;
        }
    }
}
