// More information: https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/

// @include break-above(sm) {}
@mixin break-above($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Write the media query.
		@media (min-width: $breakpoint-value) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

// @include break-below(sm) {}
@mixin break-below($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Write the media query.
		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

// @include break-between(sm, md) {}
@mixin break-between($lower, $upper) {
	// If both the lower and upper breakpoints exist in the map.
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper)
	{
		// Get the lower and upper breakpoints.
		$lower-breakpoint: map-get($breakpoints, $lower);
		$upper-breakpoint: map-get($breakpoints, $upper);

		// Write the media query.
		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}

		// If one or both of the breakpoints don't exist.
	} @else {
		// If lower breakpoint is invalid.
		@if (map-has-key($breakpoints, $lower) == false) {
			// Log a warning.
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}

		// If upper breakpoint is invalid.
		@if (map-has-key($breakpoints, $upper) == false) {
			// Log a warning.
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}

//
//  Border Radius
//  ---------------------------------------------

@mixin border-radius($rad) {
	-moz-border-radius: $rad;
		-webkit-border-radius: $rad;
			border-radius: $rad;
}

@mixin border-radius__each($topleft, $topright, $bottomright, $bottomleft) {
	-moz-border-radius: $topleft $topright $bottomright $bottomleft;
		-webkit-border-radius: $topleft $topright $bottomright $bottomleft;
			border-radius: $topleft $topright $bottomright $bottomleft;
}

//
//  Box Shadow
//  ---------------------------------------------

@mixin box-shadow($hor, $ver, $blur, $spred, $color) {
	-moz-box-shadow: $hor $ver $blur $spred $color;
		-webkit-box-shadow: $hor $ver $blur $spred $color;
			box-shadow: $hor $ver $blur $spred $color;
}

//
//  Box Sizing
//  ---------------------------------------------

@mixin box-sizing__border-box() {
	-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
			box-sizing: border-box;
}

//
//  Flex
//  ---------------------------------------------

@mixin flex__container() {
	display: -moz-box;
		display: -ms-flexbox;
			display: -webkit-flex;
				display: flex;
}

@mixin flex__align-items($align) {  //  [flex-start, flex-end, center, baseline, stretch]
	-moz-align-items: $align;
		-ms-align-items: $align;
			-webkit-align-items: $align;
				align-items: $align;
}

@mixin flex__align-self($align) {  //  [flex-start, flex-end, center, baseline, stretch]
    -webkit-align-self: $align;
        align-self: $align;
}

@mixin flex__direction($dir) {  //  [row, row-reverse, column, column-reverse]
	-moz-flex-direction: $dir;
		-ms-flex-direction: $dir;
			-webkit-flex-direction: $dir;
				flex-direction: $dir;
}

@mixin flex__grow-shrink-basis($grow, $shrink, $basis) {
	-ms-flex: $grow $shrink $basis;
		-webkit-flex: $grow $shrink $basis;
			flex: $grow $shrink $basis;
}

@mixin flex__justify-content($align) {  //  [flex-start, flex-end, center, space-between, space-around]
	-moz-justify-content: $align;
		-ms-justify-content: $align;
			-webkit-justify-content: $align;
				justify-content: $align;
}

@mixin flex__wrap($wrap) {  //  [nowrap, wrap, wrap-reverse]
	-moz-flex-wrap: $wrap;
		-ms-flex-wrap: $wrap;
			-webkit-flex-wrap: $wrap;
				flex-wrap: $wrap;
}

//
//  Order
//  ---------------------------------------------

@mixin order($order) {  //  [1, 2, 3, ...]
	-ms-order: $order;
		-webkit-order: $order;
			order: $order;
}

//
//  Transform
//  ---------------------------------------------

@mixin transform__translateX($x) {
	-moz-transform: translateX($x);
		-ms-transform: translateX($x);
			-o-transform: translateX($x);
				-webkit-transform: translateX($x);
					transform: translateX($x);
}

@mixin transform__translateY($y) {
	-moz-transform: translateY($y);
		-ms-transform: translateY($y);
			-o-transform: translateY($y);
				-webkit-transform: translateY($y);
					transform: translateY($y);
}

@mixin transform__translateXY($x, $y) {
	-moz-transform: translateX($x) translateY($y);
		-ms-transform: translateX($x) translateY($y);
			-o-transform: translateX($x) translateY($y);
				-webkit-transform: translateX($x) translateY($y);
					transform: translateX($x) translateY($y);
}

@mixin transform__translateXY_rotate($x, $y, $deg) {
	-moz-transform: translateX($x) translateY($y) rotate($deg);
		-ms-transform: translateX($x) translateY($y) rotate($deg);
			-o-transform: translateX($x) translateY($y) rotate($deg);
				-webkit-transform: translateX($x) translateY($y) rotate($deg);
					transform: translateX($x) translateY($y) rotate($deg);
}
