.single {
    .ct-featured-image {
        max-width: 1640px;
    }

    .site-main {
        .entry-content {
            margin-bottom: 70px;

            > * {
                width: calc(100% - 30px) !important;
            }
        }
    }

    .post-edit-link {
        display: none;
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .single {
        .hero-section {
            margin-bottom: 40px !important;
        }

        .page-title {
            margin-top: 40px !important;
        }

        .site-main {
            .entry-content {
                margin-bottom: 50px;
            }
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .single {
        .hero-section {
            margin-bottom: 30px !important;
        }

        .page-title {
            margin-top: 30px !important;
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .single {
        .hero-section {
            margin-bottom: 20px !important;
        }

        .page-title {
            margin-top: 20px !important;
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .single {
        .hero-section {
            margin-bottom: 15px !important;
        }

        .page-title {
            font-size: 27px;
            line-height: 30px;
            margin-top: 15px !important;
        }

        .entry-meta {
            margin-top: 10px !important;
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .single {
        .ct-featured-image {
            padding-bottom: 20px;
        }

        .page-title {
            font-size: 23px;
            line-height: 28px;
        }

        .entry-meta {
            font-size: 15px;
        }
    }
}
