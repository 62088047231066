.ct-header {
    .ct-container {
        width: calc(100% - 30px);
    }

    [data-row*="top"] {
        padding: 12.5px 0;

        .to-main-website {
            a {
                padding-left: 30px;
                position: relative;
                text-decoration: none;

                &::before {
                    @include transform__translateXY_rotate(0, -50%, 180deg);
                    content: $icon__arrow-right;
                    font-family: $font-family__icomoon;
                    font-size: 13px;
                    left: 0;
                    line-height: 1;
                    position: absolute;
                    top: 50%;
                }
            }
        }

        .menu {
            > li.menu-item {
                &:first-child {
                    > a.ct-menu-link {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    > a.ct-menu-link {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    [data-row*="middle"] {
        padding: 12.5px 0;

        > .ct-container {
            min-height: 0 !important;
        }

        .ct-button {
            padding: 6px 25px;

            &::before {
                display: none;
            }

            &:hover {
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

//
//  Desktop XL (max-width: 1670px)
//  _____________________________________________

@media only screen and (max-width: 1670px) {
    body.site-id-2,
    body.site-id-5 {
        .ct-header {
            [data-id="logo"] {
                .site-logo-container {
                    max-height: 60px;
    
                    img {
                        max-height: 100%;
                    }
                }
            }
        }
    }

    body.site-id-3,
    body.site-id-4,
    body.site-id-7,
    body.site-id-8 {
        .ct-header {
            [data-id="logo"] {
                .site-logo-container {
                    max-height: 70px;
    
                    img {
                        max-height: 100%;
                    }
                }
            }
        }
    }

    body.site-id-6 {
        .ct-header {
            [data-id="logo"] {
                .site-logo-container {
                    max-height: 50px;
    
                    img {
                        max-height: 100%;
                    }
                }
            }
        }
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .ct-header {
        [data-row*="top"] {
            .to-main-website {
                a {
                    font-size: 18px;
                }
            }

            .menu {
                > li.menu-item {
                    > a.ct-menu-link {
                        font-size: 18px;
                        padding: 0 12px;
                    }
                }
            }
        }
    
        [data-row*="middle"] {
            .menu {
                > li.menu-item {
                    > a.ct-menu-link {
                        font-size: 18px;
                        padding: 0 12px;

                        &::after {
                            width: calc(100% - 24px);
                        }
                    }
                }
            }

            .ct-button {
                font-size: 17px;
                padding: 5px 25px;
            }
        }
    }

    body.site-id-3,
    body.site-id-5,
    body.site-id-7 {
        .ct-header {
            [data-id="logo"] {
                .site-logo-container {
                    max-height: 50px;
                }
            }
        }
    }

    body.site-id-4,
    body.site-id-8 {
        .ct-header {
            .ct-sticky-container {
                height: 85px !important;
            }

            [data-id="logo"] {
                .site-logo-container {
                    max-height: 60px;
                }
            }
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .ct-header {
        [data-row*="top"] {
            padding: 5px 0;

            .to-main-website {
                a {
                    font-size: 16px;
                }
            }

            .menu {
                > li.menu-item {
                    > a.ct-menu-link {
                        font-size: 15px;
                        padding: 0 10px;
                    }
                }
            }
        }

        .ct-sticky-container {
            height: 96px !important;
        }

        [data-row*="middle"] {
            padding: 8px 0;

            .menu {
                > li.menu-item {
                    > a.ct-menu-link {
                        font-size: 15px;
                        padding: 0 10px;

                        &::after {
                            width: calc(100% - 20px);
                        }
                    }
                }
            }

            .ct-button {
                font-size: 13px;
                line-height: 22px;
                min-height: auto;
                padding: 5px 18px;
            }
        }

        [data-id="logo"] {
            .site-logo-container {
                max-height: 80px;

                img {
                    max-height: 100%;
                }
            }
        }
    }

    body.site-id-2,
    body.site-id-6 {
        .ct-header {
            .ct-sticky-container {
                height: 76px !important;
            }
        }
    }

    body.site-id-3,
    body.site-id-5 {
        .ct-header {
            .ct-sticky-container {
                height: 66px !important;
            }
        }
    }

    body.site-id-4,
    body.site-id-8 {
        .ct-header {
            .ct-sticky-container {
                height: 66px !important;
            }

            [data-id="logo"] {
                .site-logo-container {
                    max-height: 50px;
                }
            }
        }
    }

    body.site-id-7 {
        .ct-header {
            .ct-sticky-container {
                height: 56px !important;
            }

            [data-id="logo"] {
                .site-logo-container {
                    max-height: 40px;
                }
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .ct-header {
        [data-id="logo"] {
            .site-logo-container {
                max-height: 70px;
            }
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    body.site-id-2,
    body.site-id-6 {
        .ct-header {
            [data-id="logo"] {
                .site-logo-container {
                    max-height: 50px;
                }
            }
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .ct-header {
        [data-id="logo"] {
            .site-logo-container {
                max-height: 60px;
            }
        }
    }

    body.site-id-2,
    body.site-id-3,
    body.site-id-4,
    body.site-id-5,
    body.site-id-7 {
        .ct-header {
            [data-id="logo"] {
                .site-logo-container {
                    max-height: 38px;
                }
            }
        }
    }

    body.site-id-6 {
        .ct-header {
            [data-id="logo"] {
                .site-logo-container {
                    max-height: 32px;
                }
            }
        }
    }
}

//
//  Mobile XS (max-width: 319px)
//  _____________________________________________

@media only screen and (max-width: 319px) {
    body.site-id-2,
    body.site-id-3,
    body.site-id-4,
    body.site-id-5,
    body.site-id-7 {
        .ct-header {
            [data-id="logo"] {
                .site-logo-container {
                    max-height: 32px;
                }
            }
        }
    }

    body.site-id-6 {
        .ct-header {
            [data-id="logo"] {
                .site-logo-container {
                    max-height: 26px;
                }
            }
        }
    }
}
