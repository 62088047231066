.ct-footer {
    position: relative;

    &::after {
        background: url('../../src/images/footer-1-rainbow.svg') no-repeat 0 0;
        background-size: 450px auto;
        bottom: 0;
        content: '';
        height: 285px;
        position: absolute;
        right: 0;
        width: 400px;

        body.site-id-2 &,
        body.site-id-3 &,
        body.site-id-4 &,
        body.site-id-5 &,
        body.site-id-6 &,
        body.site-id-7 &,
        body.site-id-8 & {
            height: 255px;
            width: 370px;
        }
    }

    .ct-container {
        width: calc(100% - 30px);
    }

    [data-row*="middle"] {
        > .ct-container {
            column-gap: 4.268%;
            grid-template-columns: 21.799% 21.799% 47.866%;

            body.site-id-2 &,
            body.site-id-3 &,
            body.site-id-4 &,
            body.site-id-5 &,
            body.site-id-6 &,
            body.site-id-7 &,
            body.site-id-8 & {
                column-gap: 12%;
                grid-template-columns: 21.5% 21.5% 33%;
            }
        }

        .ct-header-text {
            width: 100%;
        }

        .entry-content {
            h2 {
                font-size: 20px;
                line-height: 28px;
                margin: 0 0 25px;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    border-bottom: 1px solid $color__polo-blue;
                    margin: 0;

                    body.site-id-2 &,
                    body.site-id-3 &,
                    body.site-id-4 &,
                    body.site-id-5 &,
                    body.site-id-6 &,
                    body.site-id-7 &,
                    body.site-id-8 & {
                        border-bottom: 1px solid $color__white;
                    }

                    &:first-child {
                        border-top: 1px solid $color__polo-blue;

                        body.site-id-2 &,
                        body.site-id-3 &,
                        body.site-id-4 &,
                        body.site-id-5 &,
                        body.site-id-6 &,
                        body.site-id-7 &,
                        body.site-id-8 & {
                            border-top: 1px solid $color__white;
                        }
                    }

                    a {
                        display: block;
                        line-height: 1.5;
                        padding: 11px 35px 11px 0;
                        position: relative;
                        text-decoration: none;

                        &::after {
                            @include transform__translateY(-50%);
                            color: $color__white;
                            content: $icon__arrow-right;
                            font-family: $font-family__icomoon;
                            font-size: 15px;
                            line-height: 1;
                            position: absolute;
                            right: 0;
                            top: 50%;
                        }

                        &:hover {
                            &::after {
                                color: $color__safety-orange;
                            }
                        }
                    }
                }
            }

            p {
                &:last-of-type {
                    margin: 0;
                }

                a {
                    text-decoration: none;

                    &:hover {
                        color: $color__safety-orange;
                    }
                }
            }

            .columns {
                @include flex__container();
                column-gap: 12.739%;
            }

            .white-box {
                @include border-radius__each(20px, 20px, 0, 20px);
                background: $color__white;
                margin: 0 0 30px;
                min-width: 310px;
                padding: 18px 25px;

                p {
                    color: $color__black;
                    margin: 0;

                    a {
                        color: $color__black;

                        &:hover {
                            color: $color__safety-orange;
                        }
                    }
                }
            }
        }
    }

    [data-row*="bottom"] {
        > .ct-container {
            column-gap: 3.658%;
            grid-template-columns: 140px 3fr;

            body.site-id-2 & {
                column-gap: 2.135%;
                grid-template-columns: 180px 3fr;
            }

            body.site-id-3 & {
                column-gap: 2.135%;
                grid-template-columns: 191px 3fr;
            }

            body.site-id-4 & {
                column-gap: 2.135%;
                grid-template-columns: 223px 3fr;
            }

            body.site-id-5 & {
                column-gap: 2.135%;
                grid-template-columns: 247px 3fr;
            }

            body.site-id-6 & {
                column-gap: 2.135%;
                grid-template-columns: 231px 3fr;
            }

            body.site-id-7 & {
                column-gap: 2.135%;
                grid-template-columns: 196px 3fr;
            }

            body.site-id-8 & {
                column-gap: 2.135%;
                grid-template-columns: 155px 3fr;
            }
        }

        .ct-footer-copyright {
            ul {
                @include flex__container();
                column-gap: 30px;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin: 0;
                }
            }
        }
    }
}

//
//  Desktop XL (max-width: 1670px)
//  _____________________________________________

@media only screen and (max-width: 1670px) {
    .ct-footer {
        &::after {
            background-size: 400px auto;
            height: 250px;
            width: 350px;

            body.site-id-2 &,
            body.site-id-3 &,
            body.site-id-4 &,
            body.site-id-5 &,
            body.site-id-6 &,
            body.site-id-7 &,
            body.site-id-8 & {
                height: 220px;
                width: 350px;
            }
        }

        [data-row*="middle"] {
            > .ct-container {
                body.site-id-2 &,
                body.site-id-3 &,
                body.site-id-4 &,
                body.site-id-5 &,
                body.site-id-6 &,
                body.site-id-7 &,
                body.site-id-8 & {
                    column-gap: 9.5%;
                    grid-template-columns: 24% 24% 33%;
                }
            }
        }
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .ct-footer {
        &::after {
            background-size: 300px auto;
            height: 190px;
            width: 265px;

            body.site-id-2 &,
            body.site-id-3 &,
            body.site-id-4 &,
            body.site-id-5 &,
            body.site-id-6 &,
            body.site-id-7 &,
            body.site-id-8 & {
                height: 165px;
                width: 265px;
            }
        }

        [data-row*="middle"] {
            > .ct-container {
                body.site-id-2 &,
                body.site-id-3 &,
                body.site-id-4 &,
                body.site-id-5 &,
                body.site-id-6 &,
                body.site-id-7 &,
                body.site-id-8 & {
                    column-gap: 7%;
                    grid-template-columns: 25% 25% 36%;
                }
            }

            .entry-content {
                ul {
                    li {
                        a {
                            font-size: 15px;
                        }
                    }
                }

                .columns {
                    column-gap: 8%;
                }
            }
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .ct-footer {
        [data-row*="middle"] {
            > .ct-container {
                gap: 40px;
                grid-template-columns: 2fr 2fr;
                padding: 30px 0;

                body.site-id-2 &,
                body.site-id-3 &,
                body.site-id-4 &,
                body.site-id-5 &,
                body.site-id-6 &,
                body.site-id-7 &,
                body.site-id-8 & {
                    gap: 40px;
                    grid-template-columns: repeat(3, 1fr);
                }

                > div {
                    &:last-of-type {
                        grid-column: span 2;

                        body.site-id-2 &,
                        body.site-id-3 &,
                        body.site-id-4 &,
                        body.site-id-5 &,
                        body.site-id-6 &,
                        body.site-id-7 &,
                        body.site-id-8 & {
                            grid-column: 3;
                        }
                    }
                }
            }

            .entry-content {
                h2 {
                    margin: 0 0 20px;
                }

                ul {
                    li {
                        a {
                            font-size: 16px;
                        }
                    }
                }

                .columns {
                    column-gap: 40px;

                    .column {
                        width: calc((100% - 40px) / 2);

                        &.column-1 {
                            min-width: 310px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .ct-footer {
        &::after {
            display: none;
        }

        [data-row*="middle"] {
            > .ct-container {
                body.site-id-2 &,
                body.site-id-3 &,
                body.site-id-4 &,
                body.site-id-5 &,
                body.site-id-6 &,
                body.site-id-7 &,
                body.site-id-8 & {
                    grid-template-columns: 2fr 2fr;
                }

                > div {
                    &:nth-child(2) {
                        body.site-id-2 &,
                        body.site-id-3 &,
                        body.site-id-4 &,
                        body.site-id-5 &,
                        body.site-id-6 &,
                        body.site-id-7 &,
                        body.site-id-8 & {
                            @include order(3);
                            grid-column: span 2;
                            
                        }
                    }

                    &:last-of-type {
                        body.site-id-2 &,
                        body.site-id-3 &,
                        body.site-id-4 &,
                        body.site-id-5 &,
                        body.site-id-6 &,
                        body.site-id-7 &,
                        body.site-id-8 & {
                            grid-column: 2;
                        }
                    }
                }
            }
        }

        [data-row*="bottom"] {
            > .ct-container {
                column-gap: 40px;
                grid-template-columns: 113px 3fr;
            }

            .site-logo-container {
                max-height: 60px;

                img {
                    max-height: 100%;
                }
            }
        }
    }
}

//
//  Mobile L (max-width: 767px)
//  _____________________________________________

@media only screen and (max-width: 767px) {
    .ct-footer {
        [data-row*="middle"] {
            > .ct-container {
                body.site-id-2 &,
                body.site-id-3 &,
                body.site-id-4 &,
                body.site-id-5 &,
                body.site-id-6 &,
                body.site-id-7 &,
                body.site-id-8 & {
                    grid-template-columns: 3fr;
                }

                > div {
                    &:nth-child(2) {
                        body.site-id-2 &,
                        body.site-id-3 &,
                        body.site-id-4 &,
                        body.site-id-5 &,
                        body.site-id-6 &,
                        body.site-id-7 &,
                        body.site-id-8 & {
                            grid-column: auto;
                        }
                    }

                    &:last-of-type {
                        body.site-id-2 &,
                        body.site-id-3 &,
                        body.site-id-4 &,
                        body.site-id-5 &,
                        body.site-id-6 &,
                        body.site-id-7 &,
                        body.site-id-8 & {
                            grid-column: auto;
                        }
                    }
                }
            }
        }

        [data-row*="bottom"] {
            > .ct-container {
                column-gap: 30px;

                body.site-id-4 & {
                    grid-template-columns: 200px 3fr;
                }

                body.site-id-5 & {
                    grid-template-columns: 222px 3fr;
                }
            }

            .site-logo-container {
                max-height: 45px;
            }

            .ct-footer-copyright {
                ul {
                    column-gap: 20px;

                    body.site-id-2 &,
                    body.site-id-3 &,
                    body.site-id-4 &,
                    body.site-id-5 &,
                    body.site-id-6 &,
                    body.site-id-7 &,
                    body.site-id-8 & {
                        column-gap: 15px;
                    }
                }
            }
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .ct-footer {
        [data-row*="middle"] {
            > .ct-container {
                grid-template-columns: initial;

                > div {
                    &:last-of-type {
                        grid-column: initial;
                    }
                }
            }

            .entry-content {
                h2 {
                    margin: 0 0 15px;
                }

                .columns {
                    @include flex__direction(column);
                    row-gap: 30px;

                    .column {
                        width: 100%;
                    }
                }
            }
        }

        [data-row*="bottom"] {
            > .ct-container {
                grid-template-columns: initial;

                body.site-id-2 &,
                body.site-id-3 &,
                body.site-id-4 &,
                body.site-id-5 &,
                body.site-id-6 &,
                body.site-id-7 &,
                body.site-id-8 & {
                    grid-template-columns: initial;
                }

                > div {
                    @include flex__align-items(center);
                }
            }
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .ct-footer {
        [data-row*="middle"] {
            .entry-content {
                h2 {
                    font-size: 18px;
                    line-height: 25px;
                }

                ul {
                    li {
                        a {
                            font-size: 15px;

                            &::after {
                                font-size: 14px;
                            }
                        }
                    }
                }

                p {
                    font-size: 15px
                }

                .white-box {
                    min-width: 0;
                }

                .columns {
                    .column {
                        &.column-1 {
                            min-width: 0;
                        }
                    }
                }
            }
        }

        [data-row*="bottom"] {
            .ct-footer-copyright {
                ul {
                    @include flex__direction(column);
                    @include flex__align-items(center);

                    li {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

//
//  Mobile XS (max-width: 319px)
//  _____________________________________________

@media only screen and (max-width: 319px) {
    .ct-footer {
        [data-row*="middle"] {
            .entry-content {
                .white-box {
                    padding: 12px;
                }
            }
        }
    }
}
