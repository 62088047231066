.homepage-slideshow {
    position: relative;

    &::after {
        background: url('../../src/images/slideshow-1-rainbow.svg') no-repeat 0 0;
        background-size: 700px 609px;
        bottom: -175px;
        content: '';
        height: 609px;
        left: -70px;
        position: absolute;
        width: 700px;
        z-index: 1;
    }

    ul.slider-items {
        display: none;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        &.slick-initialized {
            display: block;
        }

        li.slider-item {
            margin: 0;

            .slideItem {
                aspect-ratio: 128/41;
                background-size: cover;
                display: block;
            }
        }

        .slick-arrow {
            background: rgba(255, 255, 255, 0.63);
            cursor: pointer;
            bottom: 40px;
            display: block;
            height: 66px;
            position: absolute;
            width: 66px;
            z-index: 1;

            &::before {
                color: $color__safety-orange;
                content: $icon__arrow-right;
                font-family: $font-family__icomoon;
                font-size: 21px;
                font-weight: 400;
                left: 50%;
                line-height: 1;
                position: absolute;
                top: 50%;
            }

            &.slick-prev {
                left: calc(50% + 1640px / 2 - 156px);

                &::before {
                    @include transform__translateXY_rotate(-50%, -50%, 180deg);
                }
            }

            &.slick-next {
                left: calc(50% + 1640px / 2 - 66px);

                &::before {
                    @include transform__translateXY_rotate(-50%, -50%, 0deg);
                }
            }

            &.slick-disabled {
                &::before {
                    opacity: 0.5;
                }
            }
        }

        .slick-dots {
            @include flex__container();
            @include transform__translateX(-50%);
            bottom: 30px;
            column-gap: 10px;
            left: 50%;
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            z-index: 1;

            li {
                @include border-radius(100%);
                border: 2px solid $color__white;
                cursor: pointer;
                display: block;
                height: 18px;
                margin: 0;
                position: relative;
                width: 18px;

                button {
                    @include border-radius(100%);
                    @include transform__translateXY(-50%, -50%);
                    background: none;
                    border: 0;
                    cursor: pointer;
                    font-size: 0;
                    height: 14px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    width: 14px;
                }

                &.slick-active {
                    button {
                        background: $color__safety-orange;
                    }
                }
            }
        }
    }
}

.home-main-container {
    margin: -106px 0 90px;
    width: 100%;

    body.site-id-2 &,
    body.site-id-3 &,
    body.site-id-4 &,
    body.site-id-5 &,
    body.site-id-6 &,
    body.site-id-7 &,
    body.site-id-8 & {
        margin-bottom: 0;
    }
}

.home-top-content {
    @include box-sizing__border-box();
    @include flex__container();
    @include flex__justify-content(space-between);
    margin: 0 auto 135px;
    max-width: 1640px;
    padding-left: 2.439%;
    position: relative;
    width: calc(100% - 30px);
    z-index: 1;

    .left-block {
        @include border-radius__each(20px, 20px, 0, 20px);
        @include box-sizing__border-box();
        @include flex__container();
        @include flex__align-items(center);
        @include flex__align-self(flex-start);
        background: $color__lochmara;
        min-height: 396px;
        padding: 70px 4.393% 70px;
        position: relative;
        width: 38.279%;
        z-index: 2;

        body.site-id-2 & {
            background: $color__forest-green;
            padding: 70px 10% 70px;
        }

        body.site-id-3 & {
            background: $color__dark-magenta;
        }

        body.site-id-4 & {
            background: $color__mandarian-orange;
        }

        body.site-id-5 & {
            background: $color__safety-orange;
        }

        body.site-id-6 & {
            background: $color__watercourse;
        }

        body.site-id-7 & {
            background: $color__gamboge;
        }

        body.site-id-8 & {
            background: $color__fire-engine-red;
        }

        h2 {
            color: $color__white;
            font-size: 50px;
            font-weight: 700;
            line-height: 1.3;
            margin: 0;
        }
    }

    .right-block {
        padding-top: 175px;
        width: 50.829%;

        p {
            &:last-of-type {
                margin: 0;
            }
        }

        .buttons-box {
            @include flex__container();
            column-gap: 20px;
            margin: 55px 0 0;

            .btn {
                margin: 0;

                a {
                    @include border-radius(20px);
                    background: $color__safety-orange;
                    border: 2px solid $color__safety-orange;
                    color: $color__white;
                    display: inline-block;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 23px;
                    padding: 8px 18px;
                    text-decoration: none;
                    text-transform: uppercase;

                    &:hover {
                        background: $color__white;
                        color: $color__safety-orange;
                    }
                }
            }
        }
    }
}

.home-onze-scholen {
    h2 {
        margin: 0 0 50px;
        text-align: center;
    }
}

.gray-container {
    background: $color__white-smoke;

    > .gb-inside-container {
        padding: 50px 0 75px;
        width: calc(100% - 30px);

        > h2 {
            margin: 0 0 40px;
            text-align: center;
        }
    }

    .columns {
        > .gb-inside-container {
            @include flex__container();
            gap: 32px;

            .col {
                width: calc((100% - 2 * 32px) / 3);

                > .gb-inside-container {
                    @include flex__container();
                    @include flex__direction(column);
                    @include flex__wrap(wrap);
                    height: 100%;
                    gap: 32px;
                }

                .box {
                    &.box-1 {
                        @include border-radius__each(20px, 0, 0, 0);
                        @include box-shadow(0, 3px, 6px, 0, rgba(0, 0, 0, 0.16));
                        height: calc((100% - 32px) / 2);
                        max-width: 100%;
                        overflow: hidden;
        
                        > .gb-inside-container {
                            background: $color__white;
                            height: 100%;
                            position: relative;
                            width: 100%;

                            .gb-block-image {
                                @include transform__translateXY(-50%, -50%);
                                left: 50%;
                                max-width: initial;
                                position: absolute;
                                top: 50%;

                                img {
                                    max-width: initial;
                                }
                            }
        
                            .block-details {
                                bottom: 0;
                                left: 0;
                                position: absolute;
                                right: 0;
                                top: 0;
                                z-index: 1;
        
                                > .gb-inside-container {
                                    @include box-sizing__border-box();
                                    @include flex__container();
                                    @include flex__direction(column);
                                    @include flex__justify-content(flex-end);
                                    height: 100%;
                                    padding: 30px 9.52%;
                                    row-gap: 15px;
                                    width: 100%;
        
                                    h3 {
                                        color: $color__white;
                                        font-size: 30px;
                                        font-weight: 500;
                                        line-height: 39px;
                                        margin: 0;
                                        text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
                                    }
        
                                    .btn {
                                        margin: 0;
        
                                        a {
                                            @include border-radius(20px);
                                            background: $color__safety-orange;
                                            border: 2px solid $color__safety-orange;
                                            color: $color__white;
                                            display: inline-block;
                                            font-size: 18px;
                                            font-weight: 700;
                                            line-height: 23px;
                                            padding: 8px 20px;
        
                                            &:hover {
                                                background: $color__white;
                                                color: $color__safety-orange;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
        
                    &.box-2 {
                        @include border-radius__each(0, 0, 0, 20px);
                        @include box-shadow(0, 3px, 6px, 0, rgba(0, 0, 0, 0.16));
                        @include flex__container();
                        @include flex__direction(column);
                        @include flex__justify-content(center);
                        @include flex__wrap(wrap);
                        background: $color__dark-magenta;
                        height: calc((100% - 32px) / 2);
                        overflow: hidden;

                        body.site-id-2 & {
                            background: $color__forest-green;
                        }

                        body.site-id-3 & {
                            background: $color__dark-magenta;
                        }

                        body.site-id-4 & {
                            background: $color__mandarian-orange;
                        }

                        body.site-id-5 & {
                            background: $color__safety-orange;
                        }

                        body.site-id-6 & {
                            background: $color__watercourse;
                        }

                        body.site-id-7 & {
                            background: $color__gamboge;
                        }

                        body.site-id-8 & {
                            background: $color__fire-engine-red;
                        }
        
                        .sidebar-contact-block {
                            > .gb-inside-container {
                                background: $color__dark-magenta;
                                padding: 0 9.52%;

                                body.site-id-2 & {
                                    background: $color__forest-green;
                                }

                                body.site-id-3 & {
                                    background: $color__dark-magenta;
                                }

                                body.site-id-4 & {
                                    background: $color__mandarian-orange;
                                }

                                body.site-id-5 & {
                                    background: $color__safety-orange;
                                }

                                body.site-id-6 & {
                                    background: $color__watercourse;
                                }

                                body.site-id-7 & {
                                    background: $color__gamboge;
                                }

                                body.site-id-8 & {
                                    background: $color__fire-engine-red;
                                }
                            }
        
                            p {
                                &.btn {
                                    a {
                                        background: $color__dark-magenta;

                                        body.site-id-2 & {
                                            background: $color__forest-green;
                                        }

                                        body.site-id-3 & {
                                            background: $color__dark-magenta;
                                        }

                                        body.site-id-4 & {
                                            background: $color__mandarian-orange;
                                        }

                                        body.site-id-5 & {
                                            background: $color__safety-orange;
                                        }

                                        body.site-id-6 & {
                                            background: $color__watercourse;
                                        }

                                        body.site-id-7 & {
                                            background: $color__gamboge;
                                        }

                                        body.site-id-8 & {
                                            background: $color__fire-engine-red;
                                        }
        
                                        &:hover {
                                            background: $color__white;
                                            color: $color__dark-magenta;

                                            body.site-id-2 & {
                                                background: $color__white;
                                                color: $color__forest-green;
                                            }

                                            body.site-id-3 & {
                                                background: $color__white;
                                                color: $color__dark-magenta;
                                            }

                                            body.site-id-4 & {
                                                background: $color__white;
                                                color: $color__mandarian-orange;
                                            }

                                            body.site-id-5 & {
                                                background: $color__white;
                                                color: $color__safety-orange;
                                            }

                                            body.site-id-6 & {
                                                background: $color__white;
                                                color: $color__watercourse;
                                            }

                                            body.site-id-7 & {
                                                background: $color__white;
                                                color: $color__gamboge;
                                            }

                                            body.site-id-8 & {
                                                background: $color__white;
                                                color: $color__fire-engine-red;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
        
                    &.box-3 {
                        @include box-shadow(0, 3px, 6px, 0, rgba(0, 0, 0, 0.16));
                        background: $color__white;
                        height: 100%;
                        overflow: hidden;

                        > .gb-inside-container {
                            height: 100%;
                        }
        
                        .homepage-posts {
                            height: 100%;
                            list-style: none;
                            margin: 0;
                            padding: 0;

                            .homepage-post {
                                height: 100%;
                                margin: 0;

                                .post-thumbnail {
                                    display: block;
                                    padding-top: 65.524%;
                                    position: relative;
                                    width: 100%;

                                    a {
                                        @include flex__container();
                                        @include flex__justify-content(center);
                                        @include flex__align-items(center);
                                        bottom: 0;
                                        left: 0;
                                        position: absolute;
                                        right: 0;
                                        top: 0;

                                        img {
                                            margin: 0;
                                            max-height: 100%;
                                            width: auto;
                                        }
                                    }
                                }

                                .post-details {
                                    @include flex__container();
                                    @include flex__direction(column);
                                    padding: 45px 9.524% 30px;

                                    h5 {
                                        color: $color__pelorous;
                                        font-size: 20px;
                                        font-weight: 700;
                                        line-height: 28px;
                                        margin: 0 0 15px;
                                    }

                                    .post-title {
                                        color: $color__lochmara;
                                        font-size: 30px;
                                        font-weight: 500;
                                        line-height: 39px;
                                        margin: 0 0 30px;
                                        max-height: 39px;
                                        overflow: hidden;

                                        a {
                                            color: $color__lochmara;

                                            &:hover {
                                                color: $color__safety-orange;
                                            }
                                        }
                                    }

                                    .post-excerpt {
                                        max-height: 120px;
                                        overflow: hidden;
                                    }

                                    .read-more {
                                        @include flex__align-self(flex-end);
                                        color: $color__safety-orange;
                                        font-size: 18px;
                                        font-weight: 700;
                                        line-height: 28px;
                                        margin-top: 40px;
                                        padding-right: 28px;
                                        position: relative;

                                        &::after {
                                            @include transform__translateY(-50%);
                                            content: $icon__arrow-right;
                                            font-family: $font-family__icomoon;
                                            font-size: 12px;
                                            line-height: 1;
                                            position: absolute;
                                            right: 0;
                                            top: 50%;
                                        }

                                        &:hover {
                                            color: $color__lochmara;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.box-4 {
                        @include border-radius__each(0, 20px, 0, 0);
                        @include box-shadow(0, 3px, 6px, 0, rgba(0, 0, 0, 0.16));
                        background: $color__white;
                        height: calc((100% - 32px) * 0.6);
                        overflow: hidden;

                        > .gb-inside-container {
                            @include flex__container();
                            @include flex__direction(column);
                            height: 100%;
                            padding: 30px 9.524%;
                            row-gap: 30px;
                        }

                        h5 {
                            color: $color__pelorous;
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 28px;
                            margin: 0;
                        }

                        .coming-soon-agenda-items {
                            @include flex__container();
                            @include flex__direction(column);
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            row-gap: 30px;

                            .coming-soon-agenda-item {
                                @include flex__container();
                                @include flex__align-items(center);
                                column-gap: 30px;
                                margin: 0;

                                .event-date {
                                    @include flex__align-self(flex-start);

                                    .event-label {
                                        @include border-radius(15px);
                                        @include flex__container();
                                        @include flex__align-items(center);
                                        @include flex__direction(column);
                                        @include flex__justify-content(center);
                                        color: $color__white;
                                        height: 58px;
                                        width: 56px;
                        
                                        .month {
                                            font-size: 12px;
                                            font-weight: 500;
                                            line-height: 1;
                                            text-transform: capitalize;
                                        }
                        
                                        .day {
                                            font-size: 25px;
                                            font-weight: 700;
                                            line-height: 1;
                                        }
                                    }
                                }

                                .event-details {
                                    .date {
                                        color: $color__pelorous;
                                        font-size: 15px;
                                        line-height: 19px;
                                        margin: 0 0 2px;
                                        text-transform: capitalize;

                                        a {
                                            color: $color__pelorous;

                                            &:hover {
                                                color: $color__safety-orange;
                                            }
                                        }
                                    }

                                    .event-title {
                                        color: $color__black;
                                        font-size: 20px;
                                        line-height: 27px;
                                        margin: 0;

                                        a {
                                            color: $color__black;

                                            &:hover {
                                                color: $color__safety-orange;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .view-more {
                            @include flex__align-self(flex-end);
                            margin: 0;

                            a {
                                color: $color__safety-orange;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 28px;
                                padding-right: 28px;
                                position: relative;

                                &::after {
                                    @include transform__translateY(-50%);
                                    content: $icon__arrow-right;
                                    font-family: $font-family__icomoon;
                                    font-size: 12px;
                                    line-height: 1;
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                }

                                &:hover {
                                    color: $color__lochmara;
                                }
                            }
                        }
                    }

                    &.box-5 {
                        height: calc((100% - 32px) * 0.4);
                        background: $color__forest-green;
                        max-width: 100%;
                        overflow: hidden;

                        body.site-id-3 & {
                            background: $color__dark-magenta;
                        }

                        body.site-id-4 & {
                            background: $color__mandarian-orange;
                        }

                        body.site-id-5 & {
                            background: $color__safety-orange;
                        }

                        body.site-id-6 & {
                            background: $color__watercourse;
                        }

                        body.site-id-7 & {
                            background: $color__gamboge;
                        }

                        body.site-id-8 & {
                            background: $color__fire-engine-red;
                        }

                        > .gb-inside-container {
                            background: $color__forest-green;
                            height: 100%;
                            position: relative;
                            width: 100%;

                            body.site-id-3 & {
                                background: $color__dark-magenta;
                            }

                            body.site-id-4 & {
                                background: $color__mandarian-orange;
                            }

                            body.site-id-5 & {
                                background: $color__safety-orange;
                            }

                            body.site-id-6 & {
                                background: $color__watercourse;
                            }

                            body.site-id-7 & {
                                background: $color__gamboge;
                            }

                            body.site-id-8 & {
                                background: $color__fire-engine-red;
                            }

                            .gb-block-image {
                                @include transform__translateXY(-50%, -50%);
                                left: 50%;
                                max-width: initial;
                                position: absolute;
                                top: 50%;

                                img {
                                    max-width: initial;
                                }
                            }
        
                            .block-details {
                                bottom: 0;
                                left: 0;
                                position: absolute;
                                right: 0;
                                top: 0;
                                z-index: 1;
        
                                > .gb-inside-container {
                                    @include box-sizing__border-box();
                                    @include flex__container();
                                    @include flex__direction(column);
                                    @include flex__justify-content(flex-end);
                                    height: 100%;
                                    padding: 30px 5.714%;
                                    row-gap: 15px;
                                    width: 100%;
        
                                    h3 {
                                        color: $color__white;
                                        font-size: 30px;
                                        font-weight: 500;
                                        line-height: 39px;
                                        margin: 0;
                                        text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
                                    }
        
                                    .btn {
                                        margin: 0;
        
                                        a {
                                            @include border-radius(20px);
                                            background: $color__safety-orange;
                                            border: 2px solid $color__safety-orange;
                                            color: $color__white;
                                            display: inline-block;
                                            font-size: 18px;
                                            font-weight: 700;
                                            line-height: 23px;
                                            padding: 8px 20px;
        
                                            &:hover {
                                                background: $color__white;
                                                color: $color__safety-orange;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.home-bottom-content {
    > .gb-inside-container {
        @include flex__container();
        @include flex__justify-content(space-between);
        padding: 85px 0 100px;
        width: calc(100% - 30px);

        figure {
            margin: 0;
            width: 43.293%;
        }

        .content {
            @include flex__align-self(center);
            width: 49.696%;

            > .gb-inside-container {
                h2 {
                    margin: 0 0 50px;
                }

                p {
                    &:last-of-type {
                        margin: 0;
                    }
                }

                .buttons-box {
                    margin: 50px 0 0;

                    > .gb-inside-container {
                        @include flex__container();
                        column-gap: 20px;

                        .btn {
                            margin: 0;

                            a {
                                @include border-radius(20px);
                                background: $color__safety-orange;
                                border: 2px solid $color__safety-orange;
                                color: $color__white;
                                display: inline-block;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 23px;
                                padding: 8px 18px;
                                text-decoration: none;
                                text-transform: uppercase;

                                &:hover {
                                    background: $color__white;
                                    color: $color__safety-orange;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop XL (max-width: 1670px)
//  _____________________________________________

@media only screen and (max-width: 1670px) {
    .homepage-slideshow {
        &::after {
            background-size: 600px auto;
            height: 457px;
            width: 600px;
        }

        ul.slider-items {
            .slick-arrow {
                bottom: 30px;
                height: 50px;
                width: 50px;

                &::before {
                    font-size: 17px;
                }

                &.slick-prev {
                    left: auto;
                    right: 80px;
                }

                &.slick-next {
                    left: auto;
                    right: 15px;
                }
            }
        }
    }

    .home-main-container {
        margin: -80px 0 70px;
    }

    .home-top-content {
        margin: 0 auto 90px;

        .left-block {
            min-height: 350px;
            padding: 50px 4% 50px;
        }

        .right-block {
            padding-top: 130px;

            .buttons-box {
                margin: 40px 0 0;
            }
        }
    }

    .gray-container {
        .columns {
            > .gb-inside-container {
                .col {
                    .box {
                        &.box-1 {
                            > .gb-inside-container {
                                .block-details {
                                    > .gb-inside-container {
                                        padding: 30px 7%;

                                        h3 {
                                            font-size: 26px;
                                            line-height: 30px;
                                        }

                                        .btn {
                                            a {
                                                font-size: 16px;
                                                line-height: 23px;
                                                padding: 7px 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.box-2 {
                            .sidebar-contact-block {
                                > .gb-inside-container {
                                    padding: 0 7%;
                                }

                                h2 {
                                    font-size: 26px;
                                    line-height: 30px;
                                }

                                p {
                                    font-size: 15px;
                                    line-height: 26px;
                                    margin: 0 0 20px;

                                    &.phone-number {
                                        margin: 0 0 20px;

                                        a {
                                            font-size: 22px;
                                        }
                                    }

                                    &.btn {
                                        margin: 0;

                                        a {
                                            font-size: 16px;
                                            line-height: 23px;
                                            padding: 7px 20px;
                                        }
                                    }
                                }
                            }
                        }

                        &.box-3 {
                            .homepage-posts {
                                .homepage-post {
                                    .post-details {
                                        padding: 45px 7% 30px;

                                        .post-title {
                                            font-size: 26px;
                                            line-height: 30px;
                                            max-height: 30px;
                                        }
                                    }
                                }
                            }
                        }

                        &.box-4 {
                            > .gb-inside-container {
                                padding: 30px 7%;
                                row-gap: 20px;
                            }

                            .coming-soon-agenda-items {
                                row-gap: 17px;
                            }
                        }

                        &.box-5 {
                            > .gb-inside-container {
                                .block-details {
                                    > .gb-inside-container {
                                        padding: 30px 7%;

                                        h3 {
                                            font-size: 26px;
                                            line-height: 30px;
                                        }

                                        .btn {
                                            a {
                                                font-size: 16px;
                                                line-height: 23px;
                                                padding: 7px 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .homepage-slideshow {
        &::after {
            background-size: 550px auto;
            bottom: -125px;
            height: 419px;
            width: 550px;
        }
    }

    .home-top-content {
        .left-block {
            width: 40%;
        }
    }

    .home-onze-scholen {
        h2 {
            margin: 0 0 35px;
        }
    }

    .gray-container {
        > .gb-inside-container {
            > h2 {
                margin: 0 0 35px;
            }
        }

        .columns {
            > .gb-inside-container {
                .col {
                    .box {
                        &.box-1 {
                            > .gb-inside-container {
                                .block-details {
                                    > .gb-inside-container {
                                        h3 {
                                            font-size: 23px;
                                        }

                                        .btn {
                                            a {
                                                font-size: 15px;
                                                line-height: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.box-2 {
                            .sidebar-contact-block {
                                h2 {
                                    font-size: 23px;
                                    line-height: 26px;
                                }

                                p {
                                    font-size: 14px;
                                    line-height: 24px;
                                    margin: 0 0 15px;

                                    &.phone-number {
                                        margin: 0 0 15px;

                                        a {
                                            font-size: 20px;
                                        }
                                    }

                                    &.btn {
                                        margin: 0;

                                        a {
                                            font-size: 15px;
                                            line-height: 20px;
                                        }
                                    }
                                }
                            }
                        }

                        &.box-3 {
                            .homepage-posts {
                                .homepage-post {
                                    .post-details {
                                        padding: 40px 7%;

                                        h5 {
                                            font-size: 18px;
                                            line-height: 22px;
                                        }

                                        .post-title {
                                            font-size: 23px;
                                            line-height: 26px;
                                            max-height: 26px;
                                            margin: 0 0 20px;
                                        }

                                        .read-more {
                                            font-size: 16px;
                                            margin-top: 30px;
                                        }
                                    }
                                }
                            }
                        }

                        &.box-4 {
                            h5 {
                                font-size: 18px;
                                line-height: 22px;
                            }

                            > .gb-inside-container {
                                row-gap: 15px;
                            }

                            .coming-soon-agenda-items {
                                row-gap: 15px;

                                .coming-soon-agenda-item {
                                    column-gap: 20px;

                                    .event-date {
                                        .event-label {
                                            @include border-radius(12px);
                                            height: 52px;
                                            width: 50px;

                                            .day {
                                                font-size: 22px;
                                            }
                                        }
                                    }

                                    .event-details {
                                        .date {
                                            font-size: 14px;
                                            line-height: 18px;
                                        }

                                        .event-title {
                                            font-size: 18px;
                                            line-height: 20px;
                                        }
                                    }
                                }
                            }

                            .view-more {
                                a {
                                    font-size: 16px;
                                }
                            }
                        }

                        &.box-5 {
                            > .gb-inside-container {
                                .block-details {
                                    > .gb-inside-container {
                                        h3 {
                                            font-size: 22px;
                                        }

                                        .btn {
                                            a {
                                                font-size: 15px;
                                                line-height: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-bottom-content {
        > .gb-inside-container {
            padding: 70px 0;

            figure {
                width: 47%;
            }

            .content {
                width: 49%;

                > .gb-inside-container {
                    h2 {
                        margin: 0 0 35px;
                    }

                    .buttons-box {
                        margin: 35px 0 0;
                    }
                }
            }
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .homepage-slideshow {
        &::after {
            background-size: 470px auto;
            bottom: -100px;
            height: 358px;
            width: 470px;
        }

        ul.slider-items {
            .slick-arrow {
                bottom: 20px;
                height: 40px;
                width: 40px;

                &::before {
                    font-size: 14px;
                }

                &.slick-prev {
                    right: 70px;
                }
            }

            .slick-dots {
                bottom: 20px;

                li {
                    height: 16px;
                    width: 16px;

                    button {
                        height: 12px;
                        width: 12px;
                    }
                }
            }
        }
    }

    .home-main-container {
        margin: -60px 0 50px;
    }

    .home-top-content {
        margin: 0 auto 70px;

        .left-block {
            padding: 40px 4% 40px;

            h2 {
                font-size: 46px;
            }
        }

        .right-block {
            padding-top: 110px;
            width: 55%;

            .buttons-box {
                column-gap: 15px;

                .btn {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .home-onze-scholen {
        h2 {
            font-size: 32px;
            line-height: 35px;
        }
    }

    .gray-container {
        > .gb-inside-container {
            padding: 40px 0 50px;

            > h2 {
                font-size: 32px;
                line-height: 35px;
            }
        }

        .columns {
            > .gb-inside-container {
                gap: 20px;

                .col {
                    width: calc((100% - 2 * 20px) / 3);

                    > .gb-inside-container {
                        gap: 20px;
                    }

                    .box {
                        &.box-1 {
                            height: calc((100% - 20px) / 2);

                            > .gb-inside-container {
                                .block-details {
                                    > .gb-inside-container {
                                        padding: 20px 7%;

                                        h3 {
                                            font-size: 19px;
                                        }

                                        .btn {
                                            a {
                                                font-size: 13px;
                                                line-height: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.box-2 {
                            height: calc((100% - 20px) / 2);

                            .sidebar-contact-block {
                                h2 {
                                    font-size: 19px;
                                    line-height: 22px;
                                    margin: 0 0 15px !important;
                                }

                                p {
                                    font-size: 13px;
                                    line-height: 21px;

                                    &.phone-number {
                                        a {
                                            font-size: 18px;
                                        }
                                    }

                                    &.btn {
                                        margin: 0;

                                        a {
                                            font-size: 13px;
                                            line-height: 18px;
                                        }
                                    }
                                }
                            }
                        }

                        &.box-3 {
                            .homepage-posts {
                                .homepage-post {
                                    .post-details {
                                        .post-title {
                                            font-size: 19px;
                                            line-height: 23px;
                                            max-height: 23px;
                                            margin: 0 0 15px;
                                        }

                                        .read-more {
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }
                        }

                        &.box-4 {
                            height: calc((100% - 20px) * 0.6);

                            > .gb-inside-container {
                                padding: 20px 7%;
                            }

                            .coming-soon-agenda-items {
                                row-gap: 12px;

                                .coming-soon-agenda-item {
                                    column-gap: 15px;

                                    .event-date {
                                        .event-label {
                                            @include border-radius(10px);
                                            height: 46px;
                                            width: 44px;

                                            .day {
                                                font-size: 20px;
                                            }
                                        }
                                    }

                                    .event-details {
                                        .date {
                                            font-size: 13px;
                                        }

                                        .event-title {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }

                            .view-more {
                                a {
                                    font-size: 15px;
                                }
                            }
                        }

                        &.box-5 {
                            height: calc((100% - 20px) * 0.4);

                            > .gb-inside-container {
                                .block-details {
                                    > .gb-inside-container {
                                        padding: 20px 7%;

                                        h3 {
                                            font-size: 18px;
                                        }

                                        .btn {
                                            a {
                                                font-size: 13px;
                                                line-height: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-bottom-content {
        > .gb-inside-container {
            padding: 50px 0;

            .content {
                > .gb-inside-container {
                    h2 {
                        font-size: 32px;
                        line-height: 35px;
                        margin: 0 0 30px;
                    }

                    .buttons-box {
                        margin: 30px 0 0;

                        > .gb-inside-container {
                            column-gap: 15px;

                            .btn {
                                a {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .homepage-slideshow {
        &::after {
            background-size: 380px auto;
            height: 289px;
            width: 380px;
        }

        ul.slider-items {
            .slick-arrow {
                &.slick-prev {
                    right: 65px;
                }
            }
        }
    }

    .home-main-container {
        margin-bottom: 30px;
    }

    .home-top-content {
        margin: 0 auto 50px;

        .left-block {
            min-height: 300px;
            padding: 30px 4% 30px;

            h2 {
                font-size: 38px;
            }
        }

        .right-block {
            .buttons-box {
                @include flex__direction(column);
                row-gap: 10px;

                .btn {
                    width: 100%;

                    a {
                        @include box-sizing__border-box();
                        width: 100%;
                    }
                }
            }
        }
    }

    .home-onze-scholen {
        h2 {
            font-size: 30px;
            line-height: 34px;
        }
    }

    .gray-container {
        > .gb-inside-container {
            > h2 {
                font-size: 30px;
                line-height: 34px;
            }
        }

        .columns {
            > .gb-inside-container {
                @include flex__direction(column);

                .col {
                    width: 100%;

                    > .gb-inside-container {
                        @include flex__direction(row);
                        height: auto;
                    }

                    .box {
                        &.box-1 {
                            height: auto;
                            min-height: 240px;
                            width: calc((100% - 20px) / 2);
                        }

                        &.box-2 {
                            @include border-radius__each(0, 20px, 0, 0);
                            height: auto;
                            min-height: 240px;
                            width: calc((100% - 20px) / 2);
                        }

                        &.box-3 {
                            height: auto;

                            .homepage-posts {
                                .homepage-post {
                                    @include flex__container();
                                    gap: 30px;
                                    padding: 25px;

                                    .post-thumbnail {
                                        @include flex__align-self(flex-start);
                                        padding-top: 25%;
                                        width: calc((100% - 30px) * 0.4);
                                    }

                                    .post-details {
                                        padding: 0;
                                        width: calc((100% - 30px) * 0.6);

                                        .post-title {
                                            max-height: 100%;
                                        }

                                        .post-excerpt {
                                            line-height: 26px;
                                            max-height: 100%;
                                        }
                                    }
                                }
                            }
                        }

                        &.box-4 {
                            @include border-radius__each(0, 0, 0, 20px);
                            height: auto;
                            min-height: 240px;
                            width: calc((100% - 20px) / 2);
                        }

                        &.box-5 {
                            height: auto;
                            min-height: 240px;
                            width: calc((100% - 20px) / 2);
                        }
                    }
                }
            }
        }
    }

    .home-bottom-content {
        > .gb-inside-container {
            padding: 40px 0;

            .content {
                > .gb-inside-container {
                    h2 {
                        font-size: 30px;
                        line-height: 34px;
                        margin: 0 0 25px;
                    }

                    .buttons-box {
                        margin: 25px 0 0;

                        > .gb-inside-container {
                            @include flex__direction(column);
                            row-gap: 10px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Mobile L (max-width: 767px)
//  _____________________________________________

@media only screen and (max-width: 767px) {
    .homepage-slideshow {
        &::after {
            display: none;
        }

        ul.slider-items {
            .slick-arrow {
                bottom: 15px;
                height: 34px;
                width: 34px;

                &::before {
                    font-size: 12px;
                }

                &.slick-prev {
                    right: 60px;
                }
            }

            .slick-dots {
                bottom: 15px;

                li {
                    height: 14px;
                    width: 14px;

                    button {
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }
    }

    .home-main-container {
        margin: 30px 0 0;
    }

    .home-top-content {
        @include flex__direction(column);
        column-gap: 0;
        padding-left: 0;
        row-gap: 30px;

        .left-block {
            @include flex__justify-content(center);
            margin: 0;
            min-height: 200px;
            padding: 20px 8%;
            width: 100%;

            h2 {
                font-size: 36px;
                line-height: 1.5;
            }
        }

        .right-block {
            padding: 0;
            width: 100%;

            .buttons-box {
                @include flex__direction(row);
                margin: 30px 0 0;

                .btn {
                    width: auto;
                }
            }
        }
    }

    .gray-container {
        .columns {
            > .gb-inside-container {
                .col {
                    .box {
                        &.box-2 {
                            .sidebar-contact-block {
                                h2 {
                                    font-size: 18px;
                                    margin: 0 0 10px !important;
                                }

                                p {
                                    margin: 0 0 10px;

                                    &.phone-number {
                                        margin: 0 0 10px;

                                        a {
                                            font-size: 17px;
                                        }
                                    }
                                }
                            }
                        }

                        &.box-3 {
                            .homepage-posts {
                                .homepage-post {
                                    padding: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-bottom-content {
        > .gb-inside-container {
            @include flex__direction(column);
            row-gap: 30px;
            padding: 30px 0;

            figure {
                width: 100%;
            }

            .content {
                @include flex__align-self(flex-start);
                width: 100%;

                > .gb-inside-container {
                    .buttons-box {
                        > .gb-inside-container {
                            @include flex__direction(row);
                        }
                    }
                }
            }
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .homepage-slideshow {
        ul.slider-items {
            .slick-arrow {
                display: none !important;
            }

            .slick-dots {
                bottom: 10px;
                column-gap: 8px;
            }
        }
    }

    .home-top-content {
        .left-block {
            min-height: 180px;

            h2 {
                font-size: 32px;
            }
        }

        .right-block {
            .buttons-box {
                @include flex__direction(column);
                margin: 20px 0 0;

                .btn {
                    width: 100%;
                }
            }
        }
    }

    .home-onze-scholen {
        h2 {
            font-size: 27px;
            line-height: 30px;
            margin: 0 0 20px;
        }
    }

    .gray-container {
        > .gb-inside-container {
            padding: 30px 0 40px;

            > h2 {
                font-size: 27px;
                line-height: 30px;
                margin: 0 0 20px;
            }
        }

        .columns {
            > .gb-inside-container {
                .col {
                    > .gb-inside-container {
                        @include flex__direction(column);
                    }

                    .box {
                        &.box-1 {
                            @include border-radius__each(15px, 15px, 0, 0);
                            min-height: initial;
                            width: 100%;

                            > .gb-inside-container {
                                height: 250px;
                            }
                        }

                        &.box-2 {
                            @include border-radius__each(0, 0, 0, 0);
                            width: 100%;
                        }

                        &.box-3 {
                            .homepage-posts {
                                .homepage-post {
                                    @include flex__direction(column);
                                    gap: 20px;
                                    padding: 20px;

                                    .post-thumbnail {
                                        padding-top: 65%;
                                        width: 100%;
                                    }

                                    .post-details {
                                        width: 100%;

                                        .post-excerpt {
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }
                        }

                        &.box-4 {
                            @include border-radius__each(0, 0, 0, 0);
                            min-height: initial;
                            width: 100%;
                        }

                        &.box-5 {
                            @include border-radius__each(0, 0, 0, 15px);
                            min-height: initial;
                            width: 100%;

                            > .gb-inside-container {
                                height: 250px;
                            }
                        }
                    }
                }
            }
        }
    }

    .home-bottom-content {
        > .gb-inside-container {
            .content {
                > .gb-inside-container {
                    h2 {
                        font-size: 27px;
                        line-height: 30px;
                        margin: 0 0 20px;
                    }

                    .buttons-box {
                        margin: 20px 0 0;

                        > .gb-inside-container {
                            @include flex__direction(column);

                            .btn {
                                a {
                                    text-align: center;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .home-top-content {
        .left-block {
            h2 {
                font-size: 28px;
            }
        }
    }

    .home-onze-scholen {
        h2 {
            font-size: 25px;
            line-height: 28px;
            margin: 0 0 12px;
        }
    }

    .gray-container {
        > .gb-inside-container {
            > h2 {
                font-size: 25px;
                line-height: 28px;
            }
        }
    }

    .home-bottom-content {
        > .gb-inside-container {
            .content {
                > .gb-inside-container {
                    h2 {
                        font-size: 25px;
                        line-height: 28px;
                        margin: 0 0 15px;
                    }

                    .buttons-box {
                        margin: 15px 0 0;
                    }
                }
            }
        }
    }
}

//
//  Mobile XS (max-width: 319px)
//  _____________________________________________

@media only screen and (max-width: 319px) {
    .home-top-content {
        row-gap: 20px;

        .left-block {
            min-height: 130px;
            padding: 12px 8% 12px;

            h2 {
                font-size: 25px;
            }
        }
    }

    .gray-container {
        .columns {
            > .gb-inside-container {
                .col {
                    .box {
                        &.box-2 {
                            .sidebar-contact-block {
                                > .gb-inside-container {
                                    padding: 20px 7%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
