.main-container {
    &.two-columns-left {
        > .gb-inside-container {
            .sidebar {
                width: 32.013%;

                > .gb-inside-container {
                    @include flex__container();
                    @include flex__direction(column);
                    row-gap: 70px;
                }
            }

            .main-content {
                width: 59.757%;
            }
        }
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .main-container {
        &.two-columns-left {
            > .gb-inside-container {
                .sidebar {
                    width: 35%;
                }

                .main-content {
                    width: 60%;
                }
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .main-container {
        &.two-columns-left {
            > .gb-inside-container {
                .sidebar {
                    @include order(2);
                    width: 100%;
                }

                .main-content {
                    @include order(1);
                    margin: 0 0 40px;
                    width: 100%;
                }
            }
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .main-container {
        &.two-columns-left {
            > .gb-inside-container {
                .sidebar {
                    > .gb-inside-container {
                        row-gap: 50px;
                    }
                }
            }
        }
    }
}
