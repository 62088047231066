.sidebar-menu-block {
    > .gb-inside-container {
        h2 {
            color: $color__danube;
            font-size: 25px;
            font-weight: 500;
            line-height: 33px;
            margin: 0 0 35px;

            body.site-id-2 &,
            body.site-id-3 &,
            body.site-id-4 &,
            body.site-id-5 &,
            body.site-id-6 &,
            body.site-id-7 &,
            body.site-id-8 & {
                color: $color__lochmara;
            }
        }

        nav {
            max-width: 480px;

            button {
                display: none !important;
            }

            ul {
                @include flex__align-items(flex-start);
                @include flex__direction(column);
                gap: 0;
                list-style: none;
                margin: 0;
                padding: 0;
    
                li {
                    border-bottom: 1px solid $color__danube;
                    margin: 0;
                    width: 100%;
    
                    a {
                        color: $color__danube;
                        display: block;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 25px;
                        padding: 15px 0 15px 25px;
                        position: relative;

                        body.site-id-2 &,
                        body.site-id-3 &,
                        body.site-id-4 &,
                        body.site-id-5 &,
                        body.site-id-6 &,
                        body.site-id-7 &,
                        body.site-id-8 & {
                            color: $color__lochmara;
                        }
    
                        &::before {
                            @include transform__translateY(-50%);
                            color: $color__safety-orange;
                            content: $icon__angle-right;
                            font-family: $font-family__icomoon;
                            font-size: 13px;
                            font-weight: 400;
                            left: 0;
                            line-height: 1;
                            position: absolute;
                            top: 50%;
                        }
    
                        &:hover {
                            color: $color__sunshade;

                            body.site-id-2 &,
                            body.site-id-3 &,
                            body.site-id-4 &,
                            body.site-id-5 &,
                            body.site-id-6 &,
                            body.site-id-7 &,
                            body.site-id-8 & {
                                color: $color__sunshade;
                            }
                        }
                    }

                    &.current-menu-item {
                        a {
                            color: $color__sunshade;

                            body.site-id-2 &,
                            body.site-id-3 &,
                            body.site-id-4 &,
                            body.site-id-5 &,
                            body.site-id-6 &,
                            body.site-id-7 &,
                            body.site-id-8 & {
                                color: $color__sunshade;
                            }
                        }
                    }
                }
            }
        }
    }
}

.sidebar-contact-block {
    > .gb-inside-container {
        @include border-radius__each(0, 0, 0, 20px);
        @include box-sizing__border-box();
        @include flex__container();
        @include flex__direction(column);
        background: $color__lochmara;
        padding: 45px 9.524% 35px;

        body.site-id-2 & {
            background: $color__forest-green;
        }

        body.site-id-3 & {
            background: $color__dark-magenta;
        }

        body.site-id-4 & {
            background: $color__mandarian-orange;
        }

        body.site-id-5 & {
            background: $color__safety-orange;
        }

        body.site-id-6 & {
            background: $color__watercourse;
        }

        body.site-id-7 & {
            background: $color__gamboge;
        }

        body.site-id-8 & {
            background: $color__fire-engine-red;
        }
    }

    h2 {
        color: $color__white;
        font-size: 30px;
        font-weight: 500;
        line-height: 39px;
        margin: 0 0 35px;
    }

    p {
        color: $color__white;
        font-size: 16px;
        line-height: 30px;
        margin: 0 0 15px;

        &.phone-number {
            margin: 0 0 25px;

            a {
                color: $color__white;
                font-size: 25px;
                font-weight: 700;
                line-height: 1;
                padding-left: 38px;
                position: relative;
                text-decoration: none;

                &::before {
                    bottom: 0;
                    content: $icon__phone;
                    font-family: $font-family__icomoon;
                    font-size: 25px;
                    font-weight: 400;
                    left: 0;
                    line-height: 1;
                    position: absolute;
                }
            }
        }

        &.btn {
            margin: 0;

            a {
                @include border-radius(20px);
                background: $color__lochmara;
                border: 2px solid $color__white;
                color: $color__white;
                display: inline-block;
                font-size: 18px;
                font-weight: 700;
                line-height: 23px;
                padding: 8px 25px;
                text-decoration: none;

                body.site-id-2 & {
                    background: $color__forest-green;
                }

                body.site-id-3 & {
                    background: $color__dark-magenta;
                }

                body.site-id-4 & {
                    background: $color__mandarian-orange;
                }

                body.site-id-5 & {
                    background: $color__safety-orange;
                }

                body.site-id-6 & {
                    background: $color__watercourse;
                }

                body.site-id-7 & {
                    background: $color__gamboge;
                }

                body.site-id-8 & {
                    background: $color__fire-engine-red;
                }

                &:hover {
                    background: $color__white;
                    color: $color__lochmara;

                    body.site-id-2 & {
                        background: $color__white;
                        color: $color__forest-green;
                    }

                    body.site-id-3 & {
                        background: $color__white;
                        color: $color__dark-magenta;
                    }

                    body.site-id-4 & {
                        background: $color__white;
                        color: $color__mandarian-orange;
                    }

                    body.site-id-5 & {
                        background: $color__white;
                        color: $color__safety-orange;
                    }

                    body.site-id-6 & {
                        background: $color__white;
                        color: $color__watercourse;
                    }

                    body.site-id-7 & {
                        background: $color__white;
                        color: $color__gamboge;
                    }

                    body.site-id-8 & {
                        background: $color__white;
                        color: $color__fire-engine-red;
                    }
                }
            }
        }
    }
}

//
//  Desktop L (max-width: 1369px)
//  _____________________________________________

@media only screen and (max-width: 1369px) {
    .sidebar-menu-block {
        > .gb-inside-container {
            nav {
                ul {
                    li {
                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop M (max-width: 1199px)
//  _____________________________________________

@media only screen and (max-width: 1199px) {
    .sidebar-menu-block {
        > .gb-inside-container {
            h2 {
                margin: 0 0 20px;
            }
        }
    }

    .sidebar-contact-block {
        > .gb-inside-container {
            padding: 35px;
        }

        h2 {
            font-size: 25px;
            line-height: 28px;
            margin: 0 0 20px !important;
        }

        p {
            font-size: 15px;
            line-height: 26px;

            &.phone-number {
                margin: 0 0 20px;

                a {
                    font-size: 22px;

                    &::before {
                        font-size: 22px;
                    }
                }
            }

            &.btn {
                a {
                    font-size: 15px;
                    padding: 6px 20px;
                }
            }
        }
    }
}

//
//  Tablet (max-width: 999px)
//  _____________________________________________

@media only screen and (max-width: 999px) {
    .sidebar-menu-block,
    .sidebar-contact-block {
        width: 100% !important;
    }

    .sidebar-menu-block {
        > .gb-inside-container {
            nav {
                max-width: 100%;

                ul {
                    li {
                        a {
                            padding: 12px 0 12px 25px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Mobile M (max-width: 639px)
//  _____________________________________________

@media only screen and (max-width: 639px) {
    .sidebar-menu-block {
        > .gb-inside-container {
            nav {
                max-width: 100%;

                .wp-block-navigation__responsive-container {
                    bottom: auto;
                    display: block;
                    left: auto;
                    position: relative;
                    right: auto;
                    top: auto;
                    width: 100%;
                }
            }
        }
    }

    .sidebar-contact-block {
        > .gb-inside-container {
            padding: 30px;
        }

        h2 {
            font-size: 20px;
            line-height: 25px;
            margin: 0 0 15px !important;
        }

        p {
            &.phone-number {
                a {
                    font-size: 20px;

                    &::before {
                        font-size: 20px;
                    }
                }
            }

            &.btn {
                a {
                    font-size: 13px;
                }
            }
        }
    }
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .sidebar-menu-block {
        > .gb-inside-container {
            h2 {
                font-size: 22px;
                margin: 0 0 10px;
            }
    
            nav {
                ul {
                    li {
                        a {
                            font-size: 16px;
                            padding: 10px 0 10px 25px;
                        }
                    }
                }
            }
        }
    }

    .sidebar-contact-block {
        > .gb-inside-container {
            padding: 25px;
        }

        h2 {
            font-size: 18px;
            line-height: 23px;
            margin: 0 0 10px !important;
        }

        p {
            &.phone-number {
                a {
                    font-size: 18px;

                    &::before {
                        font-size: 18px;
                    }
                }
            }

            &.btn {
                a {
                    padding: 5px 15px;
                }
            }
        }
    }
}
