.ct-breadcrumbs {
    background: $color__white-smoke;
    display: block;
    margin: 7px 0 0;
    padding: 11px 25px;
    width: 100%;
}

//
//  Mobile S (max-width: 480px)
//  _____________________________________________

@media only screen and (max-width: 480px) {
    .ct-breadcrumbs {
        padding: 7px 15px;
    }
}
